
html, body {
    min-width: 560px;
    margin: 0;
}

/* enumerations */
ol.enum li {
    display: list-item;
    list-style-type: disc;
}

/* menu bar */
ul.navbar {
    position: fixed;
    top: 0;
    background-color: #ffffff;
    width: 100%;
    margin: 0;
    padding: 8px;
}

/* menu bar */
li.navbar {
    display: inline;
}

/* suppress outline in menu bar */
li.navbar a {
    outline: 0;
}

li a {
    /* display: block; */
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
}

/* Change the link color on hover */
li a:hover {
    background-color: #dfdfdf;
    border-radius:       5px;
}

/* main content */
.content {
    padding-top: 36px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 40px;
}

/* contact form */

form {
    min-width:             400px;
    display:               grid;
    grid-template-columns: [labels] auto [controls] 1fr;
    grid-auto-flow:        row;
    grid-gap:              .5em;
    padding:               1.2em;
    font-family:           sans-serif;
    font-size:             1em;
    border-radius:         7px;
    background:          #dfdfdf;
}

.form-label {
    grid-column: labels;
    grid-row:    auto;
    color:       #202020;
}

.form-input, .form-textarea, .form-button {
    font-family:    inherit;
    font-size:      inherit;
    border-style:   solid;
    border-width:   2px;
    border-radius:  7px;
}

.form-input, .form-textarea {
    grid-column:    controls;
    grid-row:       auto;
    padding:        .6em;
    border-color:   #d6d6d6;
}

.form-textarea {
    resize:         vertical;
}

.form-button {
    grid-column:      controls;
    grid-row:         auto;
    padding:          1em;
    font-size:        110%;
    font-weight:      bold;
    border-color:     #92af2b;
    background-color: #92af2b;
    color:            white;
    outline:          thick;
}

.form-spacer {
    grid-column:      controls;
    height:           8px;
}

.error-message {
  background-color: #ff7272;
  color: white;
  padding: 1em;
}

.success-message {
  background-color: #afc448;
  color: white;
  padding: 1em;
}

.privacypolicy ol, .privacypolicy ol ol {
    list-style-type: none;
}

.quote {
    text-align: center;
    font-style: italic;
    margin-left: 10%;
    margin-right: 10%;
    color: #728f0b;
}

.quote .message {
    font-size: x-large;    
}

.quote .author {
    white-space: nowrap;
}

/* skill table */
.skilltable {
    font-family:           sans-serif;
    font-size:             1em;
    margin-left: auto;
    margin-right: auto;
}

.skillheader {
    text-align: left;
}

.skillheader, .skillcategory {
    background-color: #b8d255;
    border-radius: 5px;
    padding: 8px;
}

.skillcategory {
    max-width: 8em;
    text-align: center;
}

.skill, .skilllevel, .skillcomment {
    border-radius: 5px;
    background-color: #eeeeee;
    padding: 8px;
    height: 1em;
}

.skillcomment {
    max-width: 40em;
}

.skill {
    font-weight: bolder;
}

.skilllabelicon {
    float: left;
    height: 1em;
    padding-right: 0;
    min-width: 1em;
    max-width: 2em;
}

.skilllabel {
    padding-left: 2.5em;
}

.skillicon {
    float: left;
    height: 1em;
    min-width: 3em;
}

/* About (catch phrase) */
.about {
    font-size: x-large;
    font-family: serif;
    text-align: center;
    font-style: italic;
}

/* service table */
.servicetable {
    font-family:           sans-serif;
    font-size:             1em;
    margin-left: auto;
    margin-right: auto;
    max-width: 65em;
}

.serviceheader {
    background-color: #b8d255;
    border-radius: 5px;
    padding: 8px;
    text-align: left;
}

.servicetitle {
    background-color: #b8d255;
    border-radius: 5px;
    padding: 8px;
    text-align: center;
}

.servicedescription {
    border-radius: 5px;
    background-color: #eeeeee;
    padding: 8px;
    height: 1em;
}
.servicedetails {
    border-radius: 5px;
    background-color: #eeeeee;
    padding: 8px;
    /*height: 1em;*/
    word-wrap: break-word;
    max-width: fit-content;
}

ul.servicekeywords {
    list-style-type: disc;
}

li.servicekeywords {
    /*list-style-type: disc;*/
}
